import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Mr. Rubber Brasil é o primeiro evento e o único concurso para fetichistas por látex no Brasil" />
    <section className="intro">
      <div className="intro-container">
        <h2>O <strong>Mr. Rubber Brasil</strong> é o primeiro evento e o único concurso para fetichistas por látex no Brasil.</h2>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Fundado em 2019, o Mr. Rubber Brasil é o primeiro evento rubber na América do Sul e o segundo concurso rubber na América Latina. A segunda edição do evento acontecerá de 23 a 25 de setembro de 2022 em São Paulo, e a segunda edição do concurso acontecerá no dia 24 de setembro de 2022 na Upgrade Club. <Link to="/sobre"><em>Leia mais</em></Link></p>
      </div>
    </section>
    <section className="polyptych">
      <div className="polyptych-container">
        <div className="polyptych-fourth">
          <h3>Evento</h3>
          <p>A próxima edição do evento Mr. Rubber Brasil se dará de 23 a 25 de setembro de 2022 em São Paulo, Brasil, e a próxima edição do concurso se dará no dia 24 de setembro de 2022 na Upgrade Club.</p>
          <p><Link to="/evento" className="call-to-action">Leia mais</Link></p>
        </div>
        <div className="polyptych-first">
          <h3>Concurso</h3>
          <p>Você tem interesse em se candidatar a ser o próximo Mr. Rubber Brasil? Confira a página do concurso para participar como candidato.</p>
          <p><Link to="/concurso" className="call-to-action">Leia mais</Link></p>
        </div>
        <div className="polyptych-third">
          <h3>Histórico</h3>
          <p>Leia mais sobre a nossa história, acesse fotos e vídeos de edições anteriores e conheça os ganhadores e jurados.</p>
          <p><Link to="/historico" className="call-to-action">Leia mais</Link></p>
        </div>
        <div className="polyptych-third">
          <h3>Sobre</h3>
          <p>Aprenda mais sobre a organização, a equipe e os objetivos do Mr. Rubber Brasil, o primeiro concurso rubber do Brasil.</p>
          <p><Link to="/sobre" className="call-to-action">Leia mais</Link></p>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
